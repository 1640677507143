var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Danh sách học sinh lớp: " + _vm.dataClassName,
            visible: _vm.dialogVisible,
            width: "70%",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: {
                data: _vm.kidsList,
                "highlight-current-row": "",
                "header-cell-style": _vm.tableHeaderColor,
                "max-height": _vm.$tableMaxHeight,
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", align: "center", width: "55" },
              }),
              _c("el-table-column", {
                attrs: { type: "index", label: "STT", width: "50" },
              }),
              _c("el-table-column", {
                attrs: { prop: "fullName", label: "Họ tên" },
              }),
              _c("el-table-column", {
                attrs: { label: "Tác vụ", width: "140", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.handleCreated(scope.row)
                              },
                            },
                          },
                          [_vm._v("Nhận xét")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogByButton("newGrade")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleCreatedCommon()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Nhận xét chung")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("EvaluatePeriodicCreateDialog", {
        ref: "EvaluatePeriodicCreateDialog",
        attrs: { dialogVisible: _vm.showDialogCreate },
        on: {
          "dialog-close": function ($event) {
            _vm.showDialogCreate = false
          },
        },
      }),
      _c("EvaluatePeriodicCreateCommonDialog", {
        ref: "EvaluatePeriodicCreateCommonDialog",
        attrs: { dialogVisible: _vm.showDialogCreateCommon },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeDialogCreateCommon()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }