var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Nhận xét chung",
        visible: _vm.dialogVisible,
        width: "70%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "evalutePeriodicContent",
          attrs: {
            "label-width": "120px",
            model: _vm.evalutePeriodicContent,
            "label-position": "top",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Nội dung nhận xét", prop: "content" } },
            [
              _c("el-input", {
                attrs: {
                  rows: 8,
                  type: "textarea",
                  placeholder: "Nhập Nội dung nhận xét tháng",
                },
                model: {
                  value: _vm.evalutePeriodicContent.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.evalutePeriodicContent, "content", $$v)
                  },
                  expression: "evalutePeriodicContent.content",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "File đính kèm" } },
            [
              _c("el-button", { attrs: { type: "text", size: "medium" } }, [
                _vm._v("Thêm File"),
              ]),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.evalutePeriodicContent.urlFileList) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("evalutePeriodicContent")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("evalutePeriodicContent")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }