var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Nhận xét tháng: " + _vm.fullNameData,
            visible: _vm.dialogVisible,
            width: "80%",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "4vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "main-content" }, [
            _c("div", { staticClass: "over-table" }, [
              _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                _vm._v(
                  "\n          Người nhận xét: " +
                    _vm._s(_vm.evaluateMonthDataKid.lastModifieBy) +
                    ",\n          " +
                    _vm._s(
                      _vm._f("formatDateTime")(
                        _vm.evaluateMonthDataKid.lastModifieDate
                      )
                    ) +
                    "\n        "
                ),
              ]),
              _c(
                "table",
                { staticClass: "table-data", attrs: { border: "" } },
                [
                  _c("thead", { staticClass: "table-header" }, [
                    _c("tr", [
                      _c("td", { staticClass: "column-index" }, [
                        _vm._v("STT"),
                      ]),
                      _c("td", { staticClass: "column-item" }, [_vm._v("Mục")]),
                      _c("td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v("Nội dung"),
                      ]),
                      _c("td", { staticClass: "table-action" }, [
                        _vm._v("Tác vụ"),
                      ]),
                    ]),
                  ]),
                  _c("tbody", { staticClass: "table-body" }, [
                    _c("tr", [
                      _c("td", { staticClass: "column-index" }, [_vm._v("1")]),
                      _c("td", { staticClass: "column-item" }, [
                        _vm._v("Nội dung"),
                      ]),
                      _c(
                        "td",
                        [
                          _c("el-input", {
                            attrs: {
                              disabled:
                                _vm.evaluateMonthDataKid.id == null
                                  ? true
                                  : false,
                              type: "textarea",
                              rows: 6,
                              placeholder: "Nhập nội dung",
                            },
                            model: {
                              value: _vm.evaluateMonthDataKid.content,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.evaluateMonthDataKid,
                                  "content",
                                  $$v
                                )
                              },
                              expression: "evaluateMonthDataKid.content",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("td", { staticClass: "table-action" }),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "column-index" }, [_vm._v("2")]),
                      _c("td", { staticClass: "column-item" }, [
                        _vm._v("File đính kèm"),
                      ]),
                      _c(
                        "td",
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: "",
                                "on-change": _vm.handleChange,
                                "file-list":
                                  _vm.evaluateMonthDataKid
                                    .evaluateMonthFileList,
                                "on-exceed": _vm.handleExceedEvaluateDate,
                                "on-remove": _vm.handleRemove,
                                "on-preview": _vm.downloadFile,
                                "auto-upload": false,
                                limit: "3",
                                multiple: "",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "text-decoration": "underline",
                                    "font-size": "12px",
                                  },
                                  attrs: {
                                    disabled:
                                      _vm.evaluateMonthDataKid.id == null
                                        ? true
                                        : false,
                                    type: "text",
                                  },
                                },
                                [_vm._v("Thêm file")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("td", { staticClass: "column-action" }),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "column-index" }, [_vm._v("3")]),
                      _c("td", { staticClass: "column-item" }, [
                        _c("div", [_vm._v("Phụ huynh phản hồi")]),
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.evaluateMonthDataKid.parentReplyCreatedBy
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "color-text-reply" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm._f("formatDateTime")(
                                  _vm.evaluateMonthDataKid.parentReplyDatetime
                                )
                              ) +
                              "\n                "
                          ),
                        ]),
                      ]),
                      _vm.evaluateMonthDataKid.parentReplyDel
                        ? _c("td", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              "\n                Nội dung đã được thu hồi\n              "
                            ),
                          ])
                        : _c("td", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.evaluateMonthDataKid.parentReplyContent
                                )
                              ),
                            ]),
                          ]),
                      _vm.evaluateMonthDataKid.parentReplyDel ||
                      !!!_vm.evaluateMonthDataKid.parentReplyContent
                        ? _c("td", { staticClass: "column-action" })
                        : _c(
                            "td",
                            { staticClass: "column-action" },
                            [
                              _vm.evaluateMonthDataKid.schoolReadReply
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "width-button",
                                      attrs: {
                                        type: "success",
                                        size: "mini",
                                        disabled: "",
                                      },
                                    },
                                    [_vm._v("Đã đọc")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      staticClass: "width-button",
                                      attrs: { type: "success", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleConfirmSchoolRead()
                                        },
                                      },
                                    },
                                    [_vm._v("Xác nhận đọc")]
                                  ),
                            ],
                            1
                          ),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "column-index" }, [_vm._v("4")]),
                      _c("td", { staticClass: "column-item" }, [
                        _c("div", [_vm._v("Giáo viên phản hồi")]),
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.evaluateMonthDataKid.teacherReplyCreatedBy
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "color-text-reply" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm._f("formatDateTime")(
                                  _vm.evaluateMonthDataKid.teacherReplyDatetime
                                )
                              ) +
                              "\n                "
                          ),
                        ]),
                      ]),
                      _vm.evaluateMonthDataKid.parentReplyDel ||
                      !!!_vm.evaluateMonthDataKid.parentReplyContent
                        ? _c(
                            "td",
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 3,
                                  disabled: "",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "td",
                            [
                              _vm.evaluateMonthDataKid.teacherReplyDel
                                ? _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 3,
                                      disabled: "",
                                    },
                                    model: {
                                      value:
                                        _vm.evaluateMonthDataKid
                                          .teacherReplyContent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.evaluateMonthDataKid,
                                          "teacherReplyContent",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "evaluateMonthDataKid.teacherReplyContent",
                                    },
                                  })
                                : _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 3,
                                      disabled:
                                        _vm.getAppTypeUserLogin != "teacher",
                                      placeholder: "Nhập nội dung",
                                    },
                                    model: {
                                      value:
                                        _vm.evaluateMonthDataKid
                                          .teacherReplyContent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.evaluateMonthDataKid,
                                          "teacherReplyContent",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "evaluateMonthDataKid.teacherReplyContent",
                                    },
                                  }),
                            ],
                            1
                          ),
                      _vm.evaluateMonthDataKid.parentReplyDel ||
                      !!!_vm.evaluateMonthDataKid.parentReplyContent ||
                      !!!_vm.evaluateMonthDataKid.teacherReplyContent
                        ? _c("td", { staticClass: "column-action" })
                        : _c(
                            "td",
                            { staticClass: "column-action" },
                            [
                              _vm.evaluateMonthDataKid.teacherReplyDel
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "width-button",
                                      attrs: { type: "success", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleTeacherUnRevoke()
                                        },
                                      },
                                    },
                                    [_vm._v("Hủy thu hồi")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      staticClass: "width-button",
                                      attrs: { type: "success", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleTeacherRevoke()
                                        },
                                      },
                                    },
                                    [_vm._v("Thu hồi")]
                                  ),
                            ],
                            1
                          ),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "column-index" }, [_vm._v("5")]),
                      _c("td", { staticClass: "column-item" }, [
                        _c("div", [_vm._v("Nhà trường phản hồi")]),
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.evaluateMonthDataKid.schoolReplyCreatedBy
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "color-text-reply" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm._f("formatDateTime")(
                                  _vm.evaluateMonthDataKid.schoolReplyDatetime
                                )
                              ) +
                              "\n                "
                          ),
                        ]),
                      ]),
                      _vm.evaluateMonthDataKid.parentReplyDel ||
                      !!!_vm.evaluateMonthDataKid.parentReplyContent
                        ? _c(
                            "td",
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 3,
                                  disabled: "",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "td",
                            [
                              _vm.evaluateMonthDataKid.schoolReplyDel
                                ? _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 3,
                                      disabled: "",
                                    },
                                    model: {
                                      value:
                                        _vm.evaluateMonthDataKid
                                          .schoolReplyContent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.evaluateMonthDataKid,
                                          "schoolReplyContent",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "evaluateMonthDataKid.schoolReplyContent",
                                    },
                                  })
                                : _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 3,
                                      disabled:
                                        _vm.getAppTypeUserLogin != "plus",
                                      placeholder: "Nhập nội dung",
                                    },
                                    model: {
                                      value:
                                        _vm.evaluateMonthDataKid
                                          .schoolReplyContent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.evaluateMonthDataKid,
                                          "schoolReplyContent",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "evaluateMonthDataKid.schoolReplyContent",
                                    },
                                  }),
                            ],
                            1
                          ),
                      _vm.evaluateMonthDataKid.parentReplyDel ||
                      !!!_vm.evaluateMonthDataKid.parentReplyContent ||
                      !!!_vm.evaluateMonthDataKid.schoolReplyContent
                        ? _c("td", { staticClass: "column-action" })
                        : _c("td", { staticClass: "column-action" }, [
                            _vm.getAppTypeUserLogin == "plus"
                              ? _c(
                                  "span",
                                  [
                                    _vm.evaluateMonthDataKid.schoolReplyDel
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "width-button",
                                            attrs: {
                                              type: "success",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleSchoolUnRevoke()
                                              },
                                            },
                                          },
                                          [_vm._v("Hủy thu hồi")]
                                        )
                                      : _c(
                                          "el-button",
                                          {
                                            staticClass: "width-button",
                                            attrs: {
                                              type: "success",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleSchoolRevoke()
                                              },
                                            },
                                          },
                                          [_vm._v("Thu hồi")]
                                        ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _vm._v("\n          Tháng nhận xét\n          "),
                  _c("el-date-picker", {
                    staticClass: "input-common input-date",
                    staticStyle: { width: "135px" },
                    attrs: {
                      type: "month",
                      clearable: false,
                      format: "MM-yyyy",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "Chọn tháng",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.getSearchEvaluateMonthByIdKid()
                      },
                    },
                    model: {
                      value: _vm.dataSearchMonth.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearchMonth, "date", $$v)
                      },
                      expression: "dataSearchMonth.date",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogByButton()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v("Đóng")]),
                ]
              ),
              _vm.evaluateMonthDataKid.id != null
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "medium",
                        loading: _vm.loadingButtonSaved,
                        mini: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-check" }),
                      _c("span", [_vm._v("Lưu")]),
                    ]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: "",
                        type: "success",
                        size: "medium",
                        loading: _vm.loadingButtonSaved,
                        mini: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-check" }),
                      _c("span", [_vm._v("Lưu")]),
                    ]
                  ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }